import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbDialogService, NbMenuItem, NbMenuService, NbSidebarService, NbTrigger } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';

import { AuthService } from 'src/app/services/auth/auth.service';
import { ConstantService } from 'src/app/services/constant/constant.service';
import { CustomStorageService } from 'src/app/services/custom-storage/custom-storage.service';
import { DatePipe } from '@angular/common';
import { NbPopoverDirective } from '@nebular/theme';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Router } from '@angular/router';
import { SIDENAV_MENU } from 'src/app/shared/menu/sidenav.menu';
import { TranslateService } from '@ngx-translate/core';
import { USER_MENU } from 'src/app/shared/menu/user-navbar.menu';
import { SIDENAV_MENU_B2B } from 'src/app/shared/menu/sidenav.menu-b2c';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})

export class MainComponent implements OnInit {

  @ViewChild('notificationModalDialog', { static: true }) notificationModalDialog: TemplateRef<any>;
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective; 

  userMenuItem = USER_MENU;
  user: any;
  language: any;
  userMenuItems = USER_MENU;
  langOptions = [
    { value: 'fr', label: 'Francais' },
    { value: 'en', label: 'Anglais' },
    { value: 'esp', label: 'Espagnol' },
    { value: 'de', label: 'Allemand' },
  ];
  wattzhub =
    {
      'address': 'WATTZHUB, 40 ALL DE LA MARE JODOIN 91190 GIF-SUR-YVETTE-FRANCE',
      'tva': 'FR75894518695',
      'email':  'wattzHub@contact.com',
      'siret': '89451869500016 - Evry',
      'phone': '08 92 97 62 58',
      'naf': 'NAF 6202A',
      'accountOwner': '40, ALLÉE DE LA MARE JODOIN 91190 GIF SUR YVETTE DOMICILIATION: FIDUCIAL BANQUE - 20 rue Treilhard - 75008 Paris ',
      'IBAN': 'FR9511449000010135115001J41 ',
      'BIC': 'BDEIFRPPXXX',
      'logoUrl': 'assets/images/logo-wattzhub.png',
    };
  notifications = [];
  loadingSpinner: boolean;
  noDataFound = false;
  countNotifications = 0;
  isCountShown = true;
  innerWidth: number;
  maxWidthResponsive = 450;
  notificationMenu = {
    title: 'Notifications_history',
    translateKey: 'user.menu.Notifications_history',
    data: {
      id: null
    }
  };
  enterprise;
  logo: string;
  subDomainHost;
  displayLanguageModal = false;

  constructor(
    private constantService: ConstantService,
    private readonly router: Router,
    private readonly sidebarService: NbSidebarService,
    private readonly nbMenuService: NbMenuService,
    private readonly storageService: CustomStorageService,
    private readonly translate: TranslateService,
    private readonly authService: AuthService,
    private readonly datePipe: DatePipe,
    private readonly dialogService: NbDialogService,
    private readonly notificationService: NotificationService
  ) { }

  sidenavMenuItems: NbMenuItem[] = SIDENAV_MENU;

  ngOnInit() {
    this.subDomainHost = JSON.parse(localStorage.getItem('subDomainHost'));
    this.enterprise = JSON.parse(localStorage.getItem('enterprise'));
    if (this.enterprise) {
      if (this.enterprise.mode !== 'B2B') {
        this.sidenavMenuItems[2].children.splice(3, 1);
      } else if (this.enterprise.mode === 'B2B') {
        this.sidenavMenuItems = SIDENAV_MENU_B2B;
      }
      if (this.subDomainHost) {
        this.logo = this.enterprise.logo;
      }
    }
    if (!this.subDomainHost) {
      if (this.enterprise.name === 'Smile and Charge'){
        this.logo = 'assets/images/smile and charge.png';
      } else {
        this.logo = 'assets/images/wattzhub-text-logo.svg';
      }

    }

    if (this.router.url === '/') {
      this.constantService.navigateToDashboard();
    }
    this.innerWidth = window.innerWidth;
    this.onResize(this.innerWidth);
    this.user = this.storageService.getUser();
    this.initializeLang();
    this.onLangChange();
    this.onUserMenuItemClick();
    this.getNotifications();
    this.wattzhubData();
  }

  getNotifications() {
    this.loadingSpinner = true;
    this.notificationService.getNotifications(5, 0).subscribe(
      (res: any) => {
        if (res.length > 0) {
          this.loadingSpinner = false;
          this.countNotifications = res.filter((x) => !x.isRead).length;
          this.formatNotificationsData(res);
        } else {
          this.noDataFound = true;
          this.loadingSpinner = false;
        }
      }, (error) => {
        this.noDataFound = true;
        this.loadingSpinner = false;
        this.constantService.showErrorToast('toast.error_server');
      }
    );
  }

  wattzhubData() {
    localStorage.setItem('wattzhub',  JSON.stringify(this.wattzhub) );
  }

  changeTrigger() {
    if (!this.popover.isShown) {
      this.isCountShown = false;
    } else {
      this.isCountShown = true;
    }
  }

  formatNotificationsData(notifications: any) {
    let details;
    let date;
    const notifyArray = notifications.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    for (const notification of notifyArray) {
      details = this.notificationService.getDetailsByType(notification.type);
      date = this.datePipe.transform(notification.createdAt, 'yyyy-MM-dd hh:mm');
      this.notifications.push(
        {
          id: notification._id,
          title: details.title,
          date,
          image: details.image,
          description: details.body,
          link: details.link,
          isRead: notification.isRead
        }
      );
    }
  }

  async navigate(route, id, isRead) {
    if (this.popover.isShown) {
      this.isCountShown = true;
      this.popover.hide();
      if (!isRead) {
        this.markAsRead(id);
      }
      this.router.navigate([route]);
    }
  }

  markAsRead(id) {
    const idsBody = {
      ids: [
        id
      ]
    };
    this.notificationService.markAsRead(idsBody).subscribe(
      (res: any) => {
        this.notifications = [];
        this.getNotifications();
      }, (error) => {
        switch (error.status) {
          case 401:
            this.authService.logout();
            break;

          default:
            this.constantService.showErrorToast('toast.error_server');
            break;
        }
      }
    );
  }

  closePopOver() {
    if (this.popover.isShown) {
      this.isCountShown = true;
      this.popover.hide();
      this.constantService.navigateToNotifications();
    }
  }

  /**
   * Toggle display sidebar
   */
  toggleSidebar(event) {
    this.sidebarService.toggle(false);
  }

  /**
   * Listener on navbar menu item click
   */
  onUserMenuItemClick() {
    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'userContextMenuTag'),
        map(({ item: { data } }) => data)
      )
      .subscribe((data) => {
        switch (data.id) {
          case 1: // Show profile
            this.constantService.navigateToMyProfile();
            break;

          case 2: // Update profile
            this.constantService.navigateToEditProfile();
            break;

          case 3: // Change password
            this.constantService.navigateToEditPassword();
            break;

          case 4: // Language
            this.displayLanguageModal = true;
            break;

          case 5: // notifications history
            this.constantService.navigateToNotifications();
            break;

          default: // Logout
          this.deleteNotificationItem(this.notificationMenu);
          this.authService.logout();
        }
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth <= this.maxWidthResponsive) {
      this.addNotificationItem(this.notificationMenu);
      this.translateMenuItems();
    } else {
      this.deleteNotificationItem(this.notificationMenu);
    }
  }

  /**
   * Add notification history item into menu
   */
  addNotificationItem(notificationMenu) {
    const index = this.userMenuItems.findIndex((x) => (x.title === notificationMenu.title));
    if (index === -1) {
      notificationMenu.data.id = this.userMenuItems.length;
      const lastElement = this.userMenuItems[this.userMenuItems.length - 1];
      this.userMenuItems[this.userMenuItems.length - 1] = notificationMenu;
      this.userMenuItems.push(lastElement);
    }
  }

  /**
   * Delete notification history item from menu
   */
  deleteNotificationItem(notificationMenu) {
    const index = this.userMenuItems.findIndex((x) => (x.title === notificationMenu.title));
    if (index !== -1) {
      this.userMenuItems.splice(index, 1);
    }
  }


  onLangChange() {
    this.translate.use(this.language);
    localStorage.setItem('storage-language', this.language);
    this.translate.onLangChange.subscribe((event) => this.translateMenuItems());
    this.translateMenuItems();
  }

  /**
   * Translation des items
   */
  translateMenuItems() {
    this.translate.use(this.language);
    this.userMenuItems.forEach((item) => this.translateMenuItem(item));
    this.sidenavMenuItems.forEach((item) => this.translateMenuItem(item));
  }

  /**
   * Translation des sous items (item forEach)
   */
  translateMenuItem(menuItem) {
    if (menuItem.children != null) {
      menuItem.children.forEach((item) => this.translateMenuItem(item));
    }
    menuItem.title = this.translate.instant(menuItem.translateKey);
  }

  openModalNotification(dialog) {
    this.dialogService.open(dialog, { closeOnBackdropClick: true });
  }

  /**
   * Charging or reloading page after choosing any language
   */
  changeLanguage() {
    location.reload();
  }

  /**
   * function is called to edit request badge dialog.
   */
  openLanguageDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { closeOnBackdropClick: true });
  }

  /**
   * function is called to navigate to dashboard.
   */
  navigateToDashboard() {
    this.constantService.navigateToDashboard();
  }

  /**
   * function is called to initialize language.
   */
  initializeLang() {
    this.language = localStorage.getItem('storage-language');
    this.translate.use(this.language);
  }

  /**
   * function is called to retrieve full name user.
   */
  getUserDisplayName(user) {
    let username = '';
    if ( user.firstName && user.lastName ) {
      username = user.firstName + ' ' + user.lastName;
    } else {
      username = user.username;
    }
    return username;
  }
}


